






















































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommunityItemController } from '@/modules/community/home/components/community-item-controller'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    'join-dao-button': () => import('@/modules/community/common/components/join-dao-button.vue'),
    'group-cover': () => import('@/modules/community/common/components/group-cover.vue'),
  },
})
export default class CommunityItem extends Vue {
  @Prop() community!: any
  controller = new CommunityItemController()
  walletStore = walletStore

  followComminityHandler() {
    if (this.community.isFollowing) {
      this.controller.leaveDao(this.community)
    } else {
      this.controller.joinDao(this.community)
    }
  }

  openCommunityPage() {
    this.$router.push(`/community-organize/dao-detail/${this.community._id}`)
  }
}
