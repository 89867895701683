import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class CommunityItemController {
  @observable joinLoading = false;

  @asyncAction *joinDao(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      this.joinLoading = true
      yield apiService.daos.joinDao({ daoId: item.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.joinLoading = false
    }
  }

  @asyncAction *leaveDao(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      this.joinLoading = true
      yield apiService.daos.leaveDao({ daoId: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.joinLoading = false
    }
  }
}
